import useBanner from '../hooks/useBanner';
import Logo from '../imgs/Logo.webp';

const Footer = () => {
  const { visible } = useBanner();
  const currentYear = new Date().getFullYear();

  const scrollToTarget = (id) => {
    if (!visible && id === 'banner') id = 'hero';
    const target = document.getElementById(id);
    if (target) target.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <footer className="w-full p-8 bg-white">
      <div className="flex flex-row flex-wrap items-center justify-center text-center gap-x-12 md:justify-between">
        <button 
          type="button"
          aria-label='Home'
          onClick={() => scrollToTarget('banner')}
        >
          <img src={Logo} alt="Mindful Healing Counseling & Neurofeedback Logo" className="w-28" />
        </button>
        <div className="flex flex-wrap items-center justify-center gap-x-8">
          <button
            type="button"
            aria-label='About Me'
            onClick={() => scrollToTarget('aboutme')}
            className="flex font-semibold leading-6"
          >
            About Me
          </button>
          <button
            type="button"
            aria-label='Counseling'
            onClick={() => scrollToTarget('counseling')}
            className="flex font-semibold leading-6"
          >
            Counseling
          </button>
          <button
            type="button"
            aria-label='Neurofeedback'
            onClick={() => scrollToTarget('neurofeedback')}
            className="flex font-semibold leading-6"
          >
            Neurofeedback
          </button>
          <button
            type="button"
            aria-label='Contact'
            onClick={() => scrollToTarget('contact')}
            className="flex font-semibold leading-6"
          >
            Contact
          </button>
        </div>
      </div>
      <span className="block mb-4 mt-4 sm:mt-0 border-t border-blue-gray-50" />
      <p className="block font-sans text-sm antialiased font-normal leading-relaxed text-center text-blue-gray-900">
        © {currentYear} Mindful Healing Counseling & Neurofeedback, PLLC. All Rights
        Reserved.
      </p>
    </footer>
  );
};

export default Footer;
