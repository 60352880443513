import Neurofeedback1 from '../imgs/Neurofeedback1.webp';
import Neurofeedback2 from '../imgs/Neurofeedback2.webp';
import Neurofeedback3 from '../imgs/Neurofeedback3.webp';

const features = [
  {
    title: 'What is Neurofeedback?',
    image: Neurofeedback1,
    image_alt: "Diagram of neurons in the brain lighting up.",
    description:
      'Neurofeedback is a passive technique that utilizes real-time brain data and specialized equipment to assist your brain in improving its management of both body and mind.',
  },
  {
    title: 'What to Expect?',
    image: Neurofeedback2,
    image_alt: "Man relaxing in a reclining chair.",
    description:
      'During neurofeedback, sensors on your scalp track brain activity as you watch a movie or play a game that gives real-time feedback. The process is calm and painless, often improving focus and relaxation.',
  },
  {
    title: 'Potential Benefits?',
    image: Neurofeedback3,
    image_alt: "Woman smiling while surrounded by bubbles.",
    description:
      'Neurofeedback can improve focus, reduce stress, and enhance cognitive performance by training the brain to regulate its activity more effectively. It may also help with anxiety, sleep, and emotional well-being.',
  },
];

const Neurofeedback = () => {
  return (
    <section id="neurofeedback" style={{"scroll-margin-top": "124px"}}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl md:text-center">
          <p className="custom-h2">
            Professional Speciality
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Neurofeedback Therapy
          </h1>
          <p className="mt-6 text-gray-500">
            Neurofeedback is a training method designed to encourage relaxation.
            This non-invasive approach helps the brain learn to self-regulate
            and enhance its efficiency. By adjusting its frequency, the brain
            can improve its overall function.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-12 gap-y-10 pt-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map(({ title, image, image_alt, description }, index) => (
            <div
              key={index}
              className={`space-y-6 pb-2 ${index === 0 ? 'col-span-1 md:col-span-2 lg:col-span-1' : ''}`}
            >
              <div className="flex w-full items-center justify-center">
                <img
                  className="h-64 rounded-xl shadow-lg object-cover"
                  loading="lazy"
                  alt={image_alt}
                  src={image}
                />
              </div>
              <p className="flex w-full items-center justify-center font-medium text-gray-900">
                {title}
              </p>
              <p className="text-center text-sm text-gray-500">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Neurofeedback;
