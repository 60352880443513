import HeroImg from "../imgs/Hero.webp"

const Hero = () => {
  return (
    <section
      id="hero"
      className="relative isolate px-6 lg:px-8 bg-top bg-cover min-h-[32rem] h-screen"
      style={{ backgroundImage: `url(${HeroImg})` }}
    >
      <div className="mx-auto max-w-4xl py-8 sm:py-20 lg:py-40 xl:py-48 font-noto-serif">
        <h1 className="custom-title-lg">Mindful Healing</h1>
        <h1 className="custom-title-sm">Counseling & Neurofeedback</h1>
      </div>
    </section>
  );
};

export default Hero;
