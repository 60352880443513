import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import BannerProvider from './contexts/BannerContext';
import Landing from './pages/Landing';

const App = () => {
  return (
    <BannerProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </BannerProvider>
  );
};

export default App;
