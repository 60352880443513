import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

export const BannerContext = createContext();

const BannerProvider = ({ children }) => {
  const [visible, setVisible] = useState(true);

  const toggleVisibility = () => setVisible((prevVisible) => !prevVisible);

  return (
    <BannerContext.Provider
      value={{
        visible,
        toggleVisibility,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

BannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BannerProvider;
