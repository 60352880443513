import About from '../partials/About';
import Banner from '../partials/Banner';
import Contact from '../partials/Contact';
import Counseling from '../partials/Counseling';
import Footer from '../partials/Footer';
import Hero from '../partials/Hero';
import NavBar from '../partials/NavBar';
import Neurofeedback from '../partials/Neurofeedback';
import useBanner from '../hooks/useBanner';

const Landing = () => {
  const { visible } = useBanner();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {visible && <Banner />}
      <div className="relative">
        <NavBar />
        <main className="grow mx-auto">
          <Hero />
          <About />
          <Counseling />
          <Neurofeedback />
          <Contact />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
