import { useEffect, useState, useCallback } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../imgs/Logo.webp';
import useBanner from '../hooks/useBanner';

const NavBar = () => {
  const { visible } = useBanner();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleUserScroll = useCallback(() => {
    if (visible) {
      setScrolled(window.scrollY > 40);
    } else {
      setScrolled(window.scrollY > 0);
    }
  }, [visible]);

  const scrollToTarget = (id) => {
    if (!visible && id === 'banner') id = 'hero';
    const target = document.getElementById(id);
    if (target) target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    if (mobileMenuOpen) setMobileMenuOpen(false);
  };
  

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => {
      window.removeEventListener('scroll', handleUserScroll);
    };
  }, [handleUserScroll]);

  return (
    <header
      id="navbar"
      className={`${scrolled ? 'fixed' : 'absolute'} inset-x-0 top-0 z-50 text-gray-900 ${scrolled ? 'bg-white shadow-md' : ''}`}
    >
      <nav
        aria-label="Global"
        className="flex items-center justify-end lg:justify-center p-6 lg:py-0 lg:px-8"
      >
        <div className="flex lg:hidden">
          <button
            type="button"
            aria-label='Open Menu'
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 items-center">
          <button
            type="button"
            aria-label='About Me'
            onClick={() => scrollToTarget('aboutme')}
            className="flex font-semibold leading-6"
          >
            About Me
          </button>
          <button
            type="button"
            aria-label='Counseling'
            onClick={() => scrollToTarget('counseling')}
            className="flex font-semibold leading-6"
          >
            Counseling
          </button>
          <button 
            type="button"
            aria-label='Home'
            onClick={() => scrollToTarget('banner')}
          >
            <img src={Logo} alt="Mindful Healing Counseling & Neurofeedback" className="w-28 h-28" />
          </button>
          <button
            type="button"
            aria-label='Neurofeedback'
            onClick={() => scrollToTarget('neurofeedback')}
            className="flex font-semibold leading-6"
          >
            Neurofeedback
          </button>
          <button
            type="button"
            aria-label='Contact'
            onClick={() => scrollToTarget('contact')}
            className="flex font-semibold leading-6"
          >
            Contact
          </button>
        </div>
      </nav>

      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm: max-w-48 sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button 
              type="button"
              aria-label='Home'
              onClick={() => scrollToTarget('banner')}>
              <img src={Logo} className="w-12 h-12" alt="Mindful Healing Counseling & Neurofeedback" />
            </button>
            <button
              type="button"
              aria-label='Close Menu'
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <button
                  type="button"
                  aria-label='About Me'
                  onClick={() => scrollToTarget('aboutme')}
                  className="-mx-3 block w-full text-left  rounded-lg pl-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About Me
                </button>
                <button
                  type="button"
                  aria-label='Counseling'
                  onClick={() => scrollToTarget('counseling')}
                  className="-mx-3 block w-full text-left  rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Counseling
                </button>
                <button
                  type="button"
                  aria-label='Neurofeedback'
                  onClick={() => scrollToTarget('neurofeedback')}
                  className="-mx-3 block w-full text-left  rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Neurofeedback
                </button>
                <button
                  type="button"
                  aria-label='Contact'
                  onClick={() => scrollToTarget('contact')}
                  className="-mx-3 block w-full text-left  rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default NavBar;
