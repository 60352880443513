import Headshot from '../imgs/KristynDunn.jpeg';

const About = () => {
  return (
    <section
      id="aboutme"
      className="pt-16 px-6 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2"
      style={{"scroll-margin-top": "100px"}}
    >
      <div className="flex-col items-center justify-center">
        <p className="custom-h2">Welcome</p>
        <h1 className="custom-h1">Hi, I&apos;m Kristyn</h1>
        <img className="mt-4 custom-img w-96 h-120 md:hidden" src={Headshot} alt="Headshot of counselor Kristyn Dunn."/>
        <p className="pt-4 custom-p-lg">
          My passion is helping individuals heal and grow. I offer a
          multidisciplinary approach to help clients overcome challenges and
          live their best lives. I utilize an acceptance-based counseling
          approach to help you navigate difficult life situations and a variety
          of mental health issues. I combine talk therapy with advanced
          neurotechnology techniques to help clients improve their mental,
          emotional and cognitive well-being. Every client receives a customized
          neurofeedback plan that is employed to optimize maximum brain fitness.
          I use HRV biofeedback with clients to help them learn self-regulation 
          and reduce symptoms of anxiety, depression, and trauma.
          <br />
          <br />I am a Licensed Clinical Mental Health Counselor Associate
          (LCMHCA), a Nationally Certified Counselor (NCC), and a Licensed NC
          School Counselor (LCSC). Additionally, I am a Certified Heartmath
          Practitioner and Alpha-stim Provider with extensive experience in
          neurofeedback and biofeedback treatment modalities.
        </p>
      </div>
      <div className="flex w-full h-full items-center justify-center">
        <img
          className="object-cover mx-auto rounded-2xl shrink-0 w-96 h-120 hidden md:block"
          loading='lazy'
          src={Headshot}
          alt="Headshot of counselor Kristyn Dunn."
        />
      </div>
    </section>
  );
};

export default About;
