import { XMarkIcon } from '@heroicons/react/20/solid';
import useBanner from '../hooks/useBanner';

const Banner = () => {
  const { toggleVisibility } = useBanner();

  return (
    <div
      id="banner"
      className="relative isolate flex items-center gap-x-6 overflow-hidden bg-white pr-6 py-2.5 sm:px-3.5 before:flex-1"
    >
      <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2">
        <p className="text-xs sm:text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Book An Appointment Today!</strong>
          <svg
            viewBox="0 0 2 2"
            aria-hidden="true"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
          >
            <circle r={1} cx={1} cy={1} />
          </svg>
          Call or Text: 704-453-6406
        </p>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          onClick={() => toggleVisibility()}
          aria-label="Close"
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
