import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import Location from '../imgs/Charlotte.avif';

const Contact = () => {
  return (
    <section id="contact" className='w-full bg-[#dbf1f1] mt-8 py-8' style={{"scroll-margin-top": "100px"}}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12">
        <div className="flex items-center">
          <img
            className="flex h-[32rem] object-cover item-center justofy-center rounded-r-xl hidden md:block"
            loading="lazy"
            src={Location}
            alt="View of the skyline of Charlotte, NC."
          />
        </div>

        <div className="pt-4 px-6 md:pr-12 md:pl-0">
          <p className="custom-h2">Get In Touch</p>
          <h1 className="custom-h1 mt-2">Schedule An Appointment</h1>
          <p className="custom-p-lg mt-6">
            Scheduling an appointment with my Charlotte-based practice is
            straightforward and hassle-free. As a solo practitioner, I offer
            personalized care and flexible scheduling to fit your needs. Reach out
            today to find a convenient time that works for you and take the first
            step towards improved health!
          </p>
          <div className="flex flex-wrap -m-3 pt-8">
            <div className="w-full sm:w-1/2 p-3">
              <div className="p-6 lg:p-11 h-full text-center border rounded-xl shadow-sm bg-gray-50 overflow-visible">
                <div className="mb-6 relative mx-auto w-16 h-16 bg-primary rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <PhoneIcon className="h-8 text-white" />
                  </div>
                </div>
                <p className="mb-3 font-semibold text-gray-500">Phone</p>
                <p className="font-medium leading-relaxed text-wrap">
                  704-453-6406
                </p>
              </div>
            </div>

            <div className="w-full sm:w-1/2 p-3">
              <div className="p-11 md:p-4 lg:p-11 h-full text-center border rounded-xl shadow-sm bg-gray-50 overflow-visible">
                <div className="mb-6 relative mx-auto w-16 h-16 bg-primary rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <EnvelopeIcon className="h-8 text-white" />
                  </div>
                </div>
                <p className="mb-3 font-semibold text-gray-500">Email</p>
                <p className="font-medium leading-relaxed text-wrap break-all">
                  kristynadunn@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          className="h-[32rem] pt-10 object-cover block md:hidden"
          loading="lazy"
          src={Location}
          alt="View of the skyline of Charlotte, NC."
        />
      </div>
    </section>
  );
};

export default Contact;
